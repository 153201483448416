export enum Routes {
  WELCOME="/",
  GAMES="/games",
  SEAT="/seat",
  PLACE="/place",
  DETAILS="/details",
  MY_MOMENTS="/my_moments",
  MOMENT="/moment",
  ALL_MOMENTO="/all_momento",
  MOMENTO="/momento",
  EDITOR="/editor",
  DOWNLOAD_OPTIONS="/download_options",
  IMAGE="/image",
  SIGNUP="/signup",
  MOREINFO="/moreinfo",
  MOMENTO_OPTIONS="/options",
  VERIFY_SEAT="/verify_seat",
}

export enum Params {
  ID="id",
  INDEX="index",
  FROM="from",
}
