import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";

import MenuIcon from "../../assets/images/header/OpenedMenuIcon.svg";
import LogoIcon from "../../assets/images/LogoMenu.svg";

import {
  SideNavigationRow, SideNavigationWrapper, SideNavigationHeader, SideNavigationHeaderLeft,
  SideNavigationOverlay, SideNavigationRowState, SideNavigationRowText,
  StyledLink, SideNavigationHeaderRight, BottomImage, MenuItems,
} from "./styled";
import { BasicImgWrapper, BasicText, COLORS } from "../styled";

import { AppServiceContext, AppServiceInstance } from "../../services/app";
import { Routes } from "../../utils/routes";
import { StorageServiceContext } from "../../services/storage";
import { MomentServiceContext } from "../../services/moment";

export const NavigationComponent = observer((): JSX.Element => {
  const { pathname } = useLocation();

  const { navigationOpened } = useContext(AppServiceContext);

  const [initiated, setInitiated] = useState(false);

  const {
    selectedRow, selectedSeat, selectedSection, selectedGameData, selectedMoment,
    selectedFirstName, selectedLastName, selectedEmail, selectedPhone,
    selectedSectionIsSpecial, selectedSpecialSection, selectedSuite, showSuites,
    selectedVerifyLastName,
  } = useContext(StorageServiceContext);
  const { momentList } = useContext(MomentServiceContext);

  const usersPlace = useMemo(() => {
    if (selectedSectionIsSpecial) {
      if (selectedSpecialSection) {
        const specialSectionDetails = `Sec. ${selectedSpecialSection}`.substring(0, 17);
        return `(${specialSectionDetails})`;
      }
      return null;
    }

    if (showSuites && selectedSuite) {
      return `Suite ${selectedSuite}`;
    }
    if (selectedSection && selectedRow && selectedSeat) {
      return `(Sec.${selectedSection} Row.${selectedRow} Seat.${selectedSeat})`;
    }
    return null;
  }, [
    selectedSection, selectedRow, selectedSeat, selectedSectionIsSpecial, selectedSpecialSection,
    selectedSuite, showSuites,
  ]);

  const needVerifySeat = useMemo(() => {
    return !!selectedGameData?.checkLastName;
  }, [selectedGameData]);

  const userDetails = useMemo(() => {
    if (needVerifySeat) {
      if (selectedVerifyLastName) {
        return selectedVerifyLastName;
      }

      return null;
    }

    if (selectedFirstName && selectedLastName && selectedEmail && selectedPhone) {
      const details = `${selectedFirstName} ${selectedLastName}`.substring(0, 17);
      return `(${details})`;
    }
    return null;
  }, [
    selectedFirstName, selectedLastName,
    selectedEmail, selectedPhone,
    needVerifySeat, selectedVerifyLastName,
  ]);

  const gameData = useMemo(() => {
    if (!selectedGameData || !selectedGameData.team1 || !selectedGameData.team2) {
      return null;
    }

    const { team1, team2 } = selectedGameData;

    const firstTeamName = team1.name.length <= 8 ? team1.name : `${team1.name.substring(0, 8)}.`;
    const secondTeamName = team2.name.length <= 8 ? team2.name : `${team2.name.substring(0, 8)}.`;

    return `(${firstTeamName} vs. ${secondTeamName})`;
  }, [selectedGameData]);

  useEffect(() => {
    if (navigationOpened) {
      setInitiated(true);
    }
  }, [navigationOpened]);

  useEffect(() => {
    setInitiated(false);
  }, [pathname]);

  const onPrivacyClick = (e) => {
    e.preventDefault();
    window.open("https://mymomento.com/terms-of-use/", "_blank");
  };

  const onFeedbackClick = (e) => {
    e.preventDefault();
    window.open("mailto:info@mymomento.com");
  };

  const onHelpClick = (e) => {
    e.preventDefault();
    window.open("mailto:info@mymomento.com");
  };

  if (!initiated) {
    return null;
  }

  return (
    <>
      <SideNavigationOverlay
        initiated={initiated}
        opened={navigationOpened}
        onClick={AppServiceInstance.toggleNavigationBar}
      />
      <SideNavigationWrapper opened={navigationOpened} initiated={initiated}>
        <MenuItems>
          <SideNavigationHeader>
            <SideNavigationHeaderRight>
              <BasicImgWrapper src={MenuIcon} onClick={AppServiceInstance.toggleNavigationBar} />
            </SideNavigationHeaderRight>
          </SideNavigationHeader>

          <StyledLink to={Routes.GAMES}>
            <SideNavigationRow>
              <SideNavigationRowText>Select Game</SideNavigationRowText>
              {gameData && <SideNavigationRowState>{gameData}</SideNavigationRowState>}
            </SideNavigationRow>
          </StyledLink>

          <StyledLink to={Routes.SEAT} onClick={(e) => !gameData && e.preventDefault()}>
            <SideNavigationRow>
              <SideNavigationRowText disabled={!gameData}>Select Seat</SideNavigationRowText>
              {usersPlace && <SideNavigationRowState>{usersPlace}</SideNavigationRowState>}
            </SideNavigationRow>
          </StyledLink>

          <StyledLink
            to={needVerifySeat ? Routes.VERIFY_SEAT : Routes.DETAILS}
            onClick={(e) => (!usersPlace) && e.preventDefault()}
          >
            <SideNavigationRow>
              <SideNavigationRowText disabled={!usersPlace || !momentList.length}>
                Contact Info
              </SideNavigationRowText>
              {userDetails && <SideNavigationRowState>{userDetails}</SideNavigationRowState>}
            </SideNavigationRow>
          </StyledLink>

          <StyledLink
            to={Routes.MY_MOMENTS}
            onClick={(e) => (!usersPlace || !userDetails || !momentList.length)
              && e.preventDefault()}
          >
            <SideNavigationRow>
              <SideNavigationRowText disabled={!usersPlace || !userDetails || !momentList.length}>
                My Momentos
              </SideNavigationRowText>
            </SideNavigationRow>
          </StyledLink>

          <StyledLink to="Feedback" onClick={onFeedbackClick}>
            <SideNavigationRow>
              <SideNavigationRowText>Feedback</SideNavigationRowText>
            </SideNavigationRow>
          </StyledLink>

          <StyledLink to="Help" onClick={onHelpClick}>
            <SideNavigationRow>
              <SideNavigationRowText>Help</SideNavigationRowText>
            </SideNavigationRow>
          </StyledLink>

          <StyledLink to={Routes.WELCOME}>
            <SideNavigationRow>
              <SideNavigationRowText>Back To Main</SideNavigationRowText>
            </SideNavigationRow>
          </StyledLink>
        </MenuItems>

        <BottomImage>
          <BasicImgWrapper src={LogoIcon} cursor="default" />
        </BottomImage>
      </SideNavigationWrapper>
    </>
  );
});
