import styled from "styled-components";

export const Z_INDEX = {
  HIGHEST: 9999,
  HIGHER: 5555,
  HIGH: 999,
  MIDDLE: 99,
  LOW: 9,
};

export const COLORS = {
  BLUE_MAIN: "#0A7CB2",
  BLUE_DARK: "#007DB7",
  BLUE_LIGHT: "#9BA6B2",
  BLUE_TOOLBAR: "#167DEC",
  BLUE_TOOLBAR_INACTIVE: "#369DFC",
  BLUE_GIF: "#02BAFF",
  BLUE_TITLE: "#0AB0FE",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  BLACK_MAIN: "#131415",
  GREY: "#A7A4A4",
  GREY_MAIN: "#EFF5F3",
  GREY_DARK: "#252847",
  GREY_LIGHT: "#DDDDDD",
  GREY_TEXT: "#808080",
  GRADIENT: "rgba(255, 255, 255, 0.252332)",
  GREEN: "#4CE6C4",
  GREEN_DARK: "#02653B",
  GREEN_TIMELINE: "#00FF00",
  RED: "#FF0000",
  RED_LIGHT: "#BF4141",
  DARK: "#1a1919",
  BLACK_BACKGROUND_OPACITY_0: "rgba(0, 0, 0, 0)",
  BLACK_BACKGROUND_OPACITY_40: "rgba(0, 0, 0, 0.4)",
  BLACK_BACKGROUND_OPACITY_80: "rgba(0, 0, 0, 0.8)",
  BLACK_BACKGROUND_OPACITY_90: "rgba(0, 0, 0, 0.9)",
  GREEN_DARK_WITH_OPACITY: "rgba(2, 101, 59, 0.6)", // #02653B
  BLUE_TITLE_WITH_OPACITY: "rgba(10, 176, 254, 0.4)",
  BLUE_DARK_2: "#003263",
};

export const GRADIENTS = {
  NEXT_BUTTON: "linear-gradient(to right, #0AB0FE, #1A6BE5)",
  WELCOME_TEXT: "linear-gradient(90deg, #0AB0FE 0%, #1A6BE5 100%)",
  MAIN_TEAM_BACK: "linear-gradient(0deg, rgba(247,247,247,1) 0%, ${teamColor1} 100%)",
};

interface LoaderWrapperProps {
  backgroundColor?: string;
}

export const LoaderWrapper = styled.div<LoaderWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: ${Z_INDEX.HIGHER};
  background-color: ${({ backgroundColor }) => backgroundColor || COLORS.BLUE_LIGHT};
`;

export enum FontFamilyTypeEnum {
  GILROY="Gilroy",
  MONTSERRAT="Montserrat",
  OSWALD="Oswald",
  GILL_SANS="GillSansC",
  APEX="Conv_Apex Mk3-Medium",
  POPPINS="Poppins",
  ANTON="Anton",
  HELVETICA="HelveticaNeueW01-87HvCnObl",
  SFPRO="SFPRO",
  Seahawks="Seahawks",
  Copperplate="Copperplate",
  Mariners="Mariners"
}

type BasicButtonProps = {
  fontFamily?: FontFamilyTypeEnum;
  background?: string;
  backgroundColor?: string;
  borderColor?: string;
  flexGrow?: number;
  margin?: string;
  width?: string;
  height?: string;
  disabled?: boolean;
  fontWeight?: string;
  fontSize?: string;
  color?: string;
  cursor?: string;
  padding?: string;
  lineHeight?: string;
}

export const BasicButton = styled.button<BasicButtonProps>`
  font-family: ${({ fontFamily }) => fontFamily || FontFamilyTypeEnum.GILROY};
  padding: ${({ padding }) => padding || "13px 37.5px 12px 37.5px"};
  border-radius: 12px;
  border: 1px solid;
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
  margin: ${({ margin }) => margin || "0 0 20px 0"};
  background: ${({ background }) => background};
  background-color: ${({ backgroundColor, disabled }) => (disabled ? COLORS.GREY : backgroundColor || COLORS.WHITE)};
  border-color: ${({ borderColor, disabled }) => (disabled ? COLORS.GREY : borderColor || COLORS.WHITE)};
  color: ${({ color }) => color || COLORS.BLACK_MAIN};
  flex-grow: ${({ flexGrow }) => flexGrow || 0};
  font-size: ${({ fontSize }) => fontSize || "12px"};
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  line-height: ${({ lineHeight }) => lineHeight || "20px"};
  cursor: ${({ cursor }) => cursor};
`;

interface BasicTextProps {
  fontFamily?: FontFamilyTypeEnum;
  color?: string;
  fontSize?: string;
  italic?: boolean;
  lineHeight?: string;
  fontWeight?: string;
  letterSpacing?: string;
  textAlign?: "left"|"center"|"right";
  margin?: string;
  underline?: boolean;
  blockWidth?: string;
  padding?: string;
  backgroundColor?: string;
  borderTopRadius?: string;
  wordWrap?: string;
  blockHeight?: string;
  overflow?: string;
}

export const BasicText = styled.div<BasicTextProps>`
  font-family: ${({ fontFamily }) => fontFamily || FontFamilyTypeEnum.GILROY};
  font-size: ${({ fontSize }) => fontSize || "12px"};
  font-style: ${({ italic }) => (italic ? "italic" : "normal")};
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  line-height: ${({ lineHeight, fontSize }) => lineHeight || fontSize || "12px"};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || "0px"};
  color: ${({ color }) => color || COLORS.BLACK_MAIN};
  text-align: ${({ textAlign }) => textAlign || "center"};
  margin: ${({ margin }) => margin || 0};
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
  width: ${({ blockWidth }) => blockWidth || "auto"};
  padding: ${({ padding }) => padding || "0px"};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-top-left-radius: ${({ borderTopRadius }) => borderTopRadius};
  border-top-right-radius: ${({ borderTopRadius }) => borderTopRadius};
  word-wrap: ${({ wordWrap }) => wordWrap};
  height: ${({ blockHeight }) => blockHeight};
  overflow: ${({ overflow }) => overflow};
`;

interface GradientTextProps {
  fontFamily?: FontFamilyTypeEnum;
  gradient: string;
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: string;
  letterSpacing?: string;
  textAlign?: "left"|"center"|"right";
  margin?: string;
  underline?: boolean;
  blockWidth?: string;
  padding?: string;
}

export const GradientText = styled.div<GradientTextProps>`
  font-family: ${({ fontFamily }) => fontFamily || FontFamilyTypeEnum.GILROY};
  font-size: ${({ fontSize }) => fontSize || "12px"};
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  line-height: ${({ lineHeight, fontSize }) => lineHeight || fontSize || "12px"};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || "0px"};
  text-align: ${({ textAlign }) => textAlign || "center"};
  margin: ${({ margin }) => margin || 0};
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
  width: ${({ blockWidth }) => blockWidth || "auto"};
  padding: ${({ padding }) => padding || "0px"};
  background: ${({ gradient }) => gradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

interface ColorSpanProps {
  color?: string;
}

export const ColorSpan = styled.span<ColorSpanProps>`
  color: ${({ color }) => color || COLORS.BLACK_MAIN};
`;

interface SeparatorLineProps {
  hasBorder?: boolean;
}

export const SeparatorLine = styled.div<SeparatorLineProps>`
  width: 100%;
  height: 10px;
  border-top: ${({ hasBorder }) => (hasBorder ? `1px solid ${COLORS.WHITE}` : "none")};
  opacity: 0.2;
`;

interface BasicContentWrapperProps {
  background?: string;
  color?: string;
  padding?: string;
  justifyContent?: "space-between"|"flex-start";
  scrollable?: boolean;
  width?: string;
  height?: string;
  position?: string;
}

export const BasicContentWrapper = styled.div<BasicContentWrapperProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  align-items: center;
  padding: ${({ padding }) => padding || "10px 16px"}; 
  color: ${({ color }) => color || COLORS.BLACK_MAIN};
  background-color: ${({ background }) => background || "transparent"};
  overflow-y: ${({ scrollable }) => (scrollable ? "scroll" : "hidden")};
  font-family: ${FontFamilyTypeEnum.GILROY};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "auto"};
  overflow-x: hidden;
  position: ${({ position }) => position};
`;

interface CommonImgWrapperProps {
  width?: string;
  height?: string;
  position?: "absolute"|"fixed";
  cursor?: string;
}

export const BasicImgWrapper = styled.img<CommonImgWrapperProps>`
  cursor: ${({ cursor }) => cursor || "pointer"};
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
  position: ${({ position }) => position || "relative"};
  object-fit: contain;
`;

interface ContentHeaderProps {
  height?: string;
  withoutPadding?: boolean;
  margin?: string;
}

export const ContentHeader = styled.div<ContentHeaderProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${({ height }) => height || "60px"};
  padding: ${({ withoutPadding }) => (withoutPadding ? "0" : "0 20px")};
  margin: ${({ margin }) => margin || 0};
`;

export const ContentHeaderCenterItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

interface ContentHeaderItemProps {
  width?: string;
}

const ContentHeaderItem = styled.div<ContentHeaderItemProps>`
  display: flex;
  align-items: center;
  width: ${({ width }) => width || "15%"};
`;

export const ContentHeaderLeftItem = styled(ContentHeaderItem)`
  justify-content: flex-start;
`;

export const ContentHeaderRightItem = styled(ContentHeaderItem)`
  justify-content: flex-end;
`;

export const ScrollableBlock = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

interface StyledInputProps {
  margin?: string;
  width?: string;
  height?: string;
  textAlign?: "center"|"left";
  padding?: string;
  borderRadius?: string;
  borderRadiusTopLeft?: string;
  borderRadiusTopRight?: string;
  borderRadiusBottomLeft?: string;
  borderRadiusBottomRight?: string;
  color?: string;
}

export const StyledInput = styled.input<StyledInputProps>`
  height: ${({ height }) => height || "50px"};
  background-color: ${COLORS.WHITE};
  border-radius: ${({ borderRadius }) => borderRadius || ""};
  border-radius-top-left: ${({ borderRadiusTopLeft }) => borderRadiusTopLeft || ""};
  border-radius-top-right: ${({ borderRadiusTopRight }) => borderRadiusTopRight || ""};
  border-radius-bottom-left: ${({ borderRadiusBottomLeft }) => borderRadiusBottomLeft || ""};
  border-radius-bottom-right: ${({ borderRadiusBottomRight }) => borderRadiusBottomRight || ""};
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.08);
  border: none;
  padding: ${({ padding }) => padding || "13px 20px"};
  margin: ${({ margin }) => margin || 0};
  text-align: ${({ textAlign }) => textAlign || "center"};
  font-family: ${FontFamilyTypeEnum.OSWALD};
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: ${({ color }) => color || COLORS.GREEN_DARK};
  mix-blend-mode: normal;
  opacity: 0.7;
  width: ${({ width }) => width || "100%"};
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  position: relative;
`;

export const PageHeaderText = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

interface MomentHeaderProps {
  height?: number;
}

export const MomentHeader = styled.div<MomentHeaderProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${({ height }) => height || 50}px;
  width: 100%;
  position: relative;
  flex-shrink: 0;
`;

interface MomentHeaderButtonsProps {
  width?: string;
  right?: string;
}

export const MomentHeaderButtons = styled.div<MomentHeaderButtonsProps>`
  position: absolute;
  right: ${({ right }) => right || "10px"};
  top: 0;
  height: 100%;
  width: ${({ width }) => width || "50px"};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

interface MomentHeaderLeftButtonProps {
  left?: string;
}

export const MomentHeaderLeftButton = styled.div<MomentHeaderLeftButtonProps>`
  position: absolute;
  left: ${({ left }) => left || "20px"};
  top: 2px;
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const MomentHeaderText = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

interface ToolbarWrapperProps {
  height: string;
  paddingTop?: string;
}

export const ToolbarWrapper = styled.div<ToolbarWrapperProps>`
  background-color: ${COLORS.WHITE};
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  height: ${({ height }) => height};
  padding-top: ${({ paddingTop }) => paddingTop || "10px"};
`;

interface ToolbarItemsProps {
  height?: string;
}

export const ToolbarItems = styled.div<ToolbarItemsProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${({ height }) => height || "100px"};
`;

interface ToolbarItemProps {
  width?: string;
  paddingTop?: string;
  justifyContent?: string;
}

export const ToolbarItem = styled.div<ToolbarItemProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
  align-items: center;
  width: ${({ width }) => width || "33%"};
  height: 100%;
  padding-top: ${({ paddingTop }) => paddingTop || "0px"};
  cursor: pointer;
`;

interface ToolbarImageWrapperProps {
  width?: string;
  height?: string;
}

export const ToolbarImageWrapper = styled.div<ToolbarImageWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height || "20px"};
  width: ${({ width }) => width || "20px"};
`;

interface ToolbarLabelProps {
  active?: boolean;
  paddingTop?: string;
  defaultColor?: string;
  activeColor?: string;
  margin?: string;
}

export const ToolbarLabel = styled(BasicText)<ToolbarLabelProps>`
  font-size: 12px;
  line-height: 12px;
  margin: ${({ margin }) => margin || "8px 0 0 0"};
  color: ${({ active, activeColor, defaultColor }) => (active ? activeColor || COLORS.BLUE_TOOLBAR : defaultColor || COLORS.BLACK)};
  font-weight: ${({ active }) => (active ? "600" : "600")};
  padding-top: ${({ paddingTop }) => paddingTop || "20px"};
`;

export const ToolbarContentLinkRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 10px;
  background-color: ${COLORS.WHITE};
`;

interface ToolbarContentLinkRowItemProps {
  width?: string;
  height?: string;
}

export const ToolbarContentLinkRowItem = styled.div<ToolbarContentLinkRowItemProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width || "33%"};
  height: ${({ height }) => height || "20px"};
`;

interface ToolbarContentLinkProps {
  activeColor?: string;
}

export const ToolbarContentLink = styled.div<ToolbarContentLinkProps>`
  border-radius: 10px;
  padding: 0px 0px 0px 0px;
  position: relative;
  color: ${({ activeColor }) => activeColor || COLORS.BLUE_TOOLBAR};
  &::after {
    content: ''; 
    position: absolute;
    left: -20px; bottom: 0px;
    border: 20px solid transparent;
    border-bottom: 20px solid ${({ activeColor }) => activeColor || COLORS.BLUE_TOOLBAR};
   }  
`;

export const ToolbarContentOver = styled.div`
  transition: all 0.4s; 
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 105px;
  padding-bottom: 10px;
  z-index: ${Z_INDEX.HIGH};
  background-color: ${COLORS.GREEN_DARK};  
`;

interface ToolbarContentRowProps {
  padding?: string;
  backgroundColor?: string;
}

export const ToolbarContentRow = styled.div<ToolbarContentRowProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  color: ${COLORS.WHITE};
  font-family: ${FontFamilyTypeEnum.SFPRO};
  font-size: 13px;
  line-height: 14px;
  background-color: ${({ backgroundColor }) => backgroundColor || COLORS.BLUE_TOOLBAR};
  padding: ${({ padding }) => padding || "0px"}
`;

interface ToolbarContentProps {
  visible?: boolean;
}

export const ToolbarContent = styled.div<ToolbarContentProps>`
  transition: all 0.4s; 
  
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 115px;
  padding: 0 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: ${Z_INDEX.HIGH};
  // background: linear-gradient(90deg, rgba(10, 124, 178, 0.397866) 0%, #0A7CB2 85.15%);
  background-color: ${COLORS.BLUE_TOOLBAR};
  
  position: absolute;
  transform: translate(0, -99%);
`;

interface ToolbarContentBelowProps {
  visible?: boolean;
  height: string;
}

export const ToolbarContentBelow = styled.div<ToolbarContentBelowProps>`
  transition: all 0.4s; 
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ height }) => height};
  background-color: ${COLORS.WHITE};
`;
