export enum PATH {
  API = "api",
  API_DOCS = "api-docs",
  FILE = "file",
  SPORT = "sport",
  LEAGUE = "league",
  TEAM = "team",
  TEAM_ADS = "teamads",
  TEAM_ADS_FOR_TEAM = "teamadsforteam",
  OPERATOR = "operator",
  STATE = "state",
  GAME = "game",
  AUTH = "auth",
  STADIUM = "stadium",
  TEMPLATE = "ticket-template",
  CITY = "city",
  POPULATED = "populated",
  CAMERA = "camera",
  LIST = "list",
  ITEM = "item",
  PREVIEW = "preview",
  PREPARE_IMAGES = "prepare-images",
  UPLOAD_MOTG_IMAGE = "upload-motg-image",
  UPLOAD_LARGE_MOTG_IMAGE = "upload-large-motg-image",
  UPLOAD_PURCHASE_IMAGE = "upload-purchase-image",
  UPDATE_USERS_UNIQUE_URLS = "update-users-unique-urls",
  CLEAR_USERS_UNIQUE_URLS = "clear-users-unique-urls",
  UPDATE_USERS_SEAT_URLS = "update-users-seats-urls",
  CREATE_NEW_USERS = "create-new-users",
  MOMENT = "moment",
  CHECK = "check",
  CHECK_LAST_NAME = "check-last-name",
  STATUS = "status",
  UPDATE_GAME_TICKET_TEMPLATE = "update-game-ticket-template",
  UPDATE_GAME_TICKET_TEMPLATES = "update-game-ticket-templates",
  UPDATE_GAME_IS_PAID_GAME = "update-game-is-paid-game",
  UPDATE_GAME_SHOW_PRINT_ORDER_BUTTON = "update-game-show-print-order-button",
  UPDATE_GAME_CHECK_LAST_NAME = "update-game-check-last-name",
  GAME_SEATS = "game-seats",
  LINKED_SEATS = "linked-seats",
  NOTIFY = "notify",
  INFO = "info",
  LINK = "link",
  ALL = "all",
  GENERAL = "general",
  SYSTEM = "system",
  OVERLAYS = "overlays",
  IMAGE = "image",
  COMPRESSED = "compressed",
  CROPPED = "cropped",
  CROPPED_EXPERIMENTAL = "cropped-experimental",
  CROPPED_VIDEO = "cropped-video",
  IMAGE_KEY = "image-key",
  PRERENDER_MOMENT = "prerender-moment",
  GET_RENDER_URL = "get-render-url",
  HEALTH = "health",
  USER = "user",
  SPECIAL_SECTIONS = "special-sections",
  ALL_SECTIONS = "all-sections",
  PAYMENT = "payment",
  GET_ALL_PAYMENTS = "get-all-payments",
  ADD_UPDATE_PAYMENT = "add-update-payment",
  ADD_PENDING_PAYMENT = "add-pending-payment",
  SHOPIFY_WEBHOOK = "shopify-webhook",
  ANALYZE = "analyze",
  GET_LAST_SESSION = "get-last-session",
  UPDATE_USER_SESSION = "update-user-session",
  SAVE_PURCHASE_LINK = "save-purchase-link",
  RESERVE_PRINT_ORDER_NUMBER = "reserve-print-order-number",
  GET_IMAGE_LINK = "get-image-link",
  CONVERT_TO_JPEG = "convert-to-jpeg",
  MEDIACLIP_GET_USER_TOKEN = "mc-get-user-token",
  MEDIACLIP_GET_BEAUTY_SHOT = "mc-get-beauty-shot",
  MEDIACLIP_GET_BEAUTY_SHOT_WITH_USER_ID = "mc-get-beauty-shot-with-user-id",
  LAMBDA = "lambda",
  GET_PROVISIONED_CONCURRENCY_CONFIG = "get-provisioned-concurrency-config",
  PUT_PROVISIONED_CONCURRENCY_CONFIG = "put-provisioned-concurrency-config",
  DELETE_PROVISIONED_CONCURRENCY_CONFIG = "delete-provisioned-concurrency-config",
}
