export const getSuiteName = (value: string) => {
  if (value?.startsWith("SUITE_")) {
    return value.substring(6, value.length);
  }

  return "";
};

export const createSuiteName = (value: string) => {
  return `SUITE_${value}`;
};

export const prepareSectionRowSeat = (section: string, row: string, seat: string, suite: string, show: boolean) => {
  if (suite && show) {
    return {
      section: createSuiteName(suite),
      row: "SUITE",
      seat: "SUITE",
    };
  }

  return {
    section,
    row,
    seat,
  };
};
