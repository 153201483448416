import axios from "axios";
import { instance } from "../instance";
import { IAxiosError } from "../interfaces";
import { getError } from "../utils";

import { GetGameMomentsRequests, BasicResponse, MomentResponseWithSize, SeatInput } from "../../shared/interfaces";
import { GetCroppedVideoRequest, PurchaseLinkImageInput } from "../../shared/interfaces/moment";

import { PATH } from "../../shared/constants";
import { getSuiteName } from "../../utils/suite";

interface FetchGameMomemntResponse {
  data: MomentResponseWithSize;
  success: boolean;
  error?: string;
}

export interface FetchSectionsRowsSeatsResponse {
  sections: string[];
  stadiumMap: string; // JSON string representing the Map structure
}

export const checkGameMomentRequest = async (
  request: GetGameMomentsRequests,
): Promise<BasicResponse> => {
  try {
    const response = await instance.post<BasicResponse>(`${PATH.API}/${PATH.MOMENT}/${PATH.CHECK}`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const checkLastNameRequest = async (
  request: SeatInput,
): Promise<BasicResponse> => {
  try {
    const response = await instance.post<BasicResponse>(`${PATH.API}/${PATH.MOMENT}/${PATH.CHECK_LAST_NAME}`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const fetchGameMomentsRequest = async (
  request: GetGameMomentsRequests,
): Promise<FetchGameMomemntResponse> => {
  try {
    const response = await instance.post<MomentResponseWithSize>(`${PATH.API}/${PATH.MOMENT}`, request);

    return {
      data: response.data,
      success: true,
    };
  } catch (err) {
    const { response } = err as IAxiosError;
    if (response.status === 404) {
      const errorFinishText = request.mappedSeat.isSpecialSection
        ? `(x: ${request.mappedSeat.seatX || ""}, y: ${request.mappedSeat.seatY || ""})`
        : `(row: ${request.mappedSeat.row}, seat: ${request.mappedSeat.seat})`;

      const suite = getSuiteName(request.mappedSeat.sectionName);

      return {
        data: null,
        success: false,
        error: `No moments found for ${suite ? "suite" : "section"} ${suite || request.mappedSeat.sectionName} ${suite ? "" : errorFinishText}`,
      };
    }
    throw new Error(getError(response));
  }
};

export const getCroppedVideo = async (
  request: GetCroppedVideoRequest,
): Promise<any> => {
  try {
    const response = await instance.post<any>(`${PATH.API}/${PATH.MOMENT}/${PATH.CROPPED_VIDEO}`, request);

    if ((response.data !== undefined) && (response.data.length === 0) && response.headers["momento-url"]) {
      const newResponse = await axios.get(response.headers["momento-url"], { responseType: "blob" });
      return newResponse.data;
    }

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getSpecialSections = async (
  stadium: string,
): Promise<any> => {
  try {
    const response = await instance.post<any>(`${PATH.API}/${PATH.MOMENT}/${PATH.SPECIAL_SECTIONS}`, {
      stadium,
    });

    return response.data.sections;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getAllSections = async (
  stadium: string,
): Promise<any> => {
  try {
    const response = await instance.post<any>(`${PATH.API}/${PATH.MOMENT}/${PATH.ALL_SECTIONS}`, {
      stadium,
    });

    return response.data.sections;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const reservePrintOrderNumber = async (
  gameId: string,
): Promise<string> => {
  try {
    const response = await instance.post(`${PATH.API}/${PATH.MOMENT}/${PATH.RESERVE_PRINT_ORDER_NUMBER}`, { gameId });

    return response?.data?.number || "";
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const addPurchaseLink = async (
  request: PurchaseLinkImageInput,
): Promise<string> => {
  try {
    const response = await instance.post(`${PATH.API}/${PATH.MOMENT}/${PATH.SAVE_PURCHASE_LINK}`, request);

    return response?.data?._id || "";
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export interface UploadLargeS3ImageResponse {
  success: boolean;
  imageKey: string;
  uploadKey: string;
  uploadURL: string;
  imageShortUrl: string;
}

export const prepareUploadLargeMOTGImage = async (
  fileName: string,
  fileMimeType: string,
  s3Folder?: string,
): Promise<UploadLargeS3ImageResponse> => {
  try {
    const response = await instance.post(`${PATH.API}/${PATH.MOMENT}/${PATH.UPLOAD_PURCHASE_IMAGE}`, {
      fileName,
      fileMimeType,
      isPurchaseLink: true,
      s3Folder,
    });

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(response.data.error);
  }
};

export const getPurchasedImage = async (
  imageId: string,
  ticketId: string,
  email: string,
): Promise<any> => {
  try {
    const response = await instance.post(`${PATH.API}/${PATH.MOMENT}/${PATH.GET_IMAGE_LINK}`, {
      authenticatedStoreUserId: email,
      ids: [imageId],
      ticketIds: [ticketId],
    }, {
      headers: {
        "X-Custom-Header": "Momento",
      },
    });
    return response;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const fetchSectionsRowsSeats = async (
  stadiumId: string,
): Promise<FetchSectionsRowsSeatsResponse> => {
  try {
    const response = await instance.get<FetchSectionsRowsSeatsResponse>(`${PATH.API}/fetch-sections-rows-seats/${stadiumId}`);
    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};
