import { MomentResponse } from "../shared/interfaces";
import { ImageCropData } from "./sharedFunctions";

/**
   * calculate view area sizes and offset
   * it must be within original area and have required aspect
   * @param originalWidth canvas width
   * @param originalHeight canvas height
   * @param aspect required aspect
   */
export const getViewAreaPosition = (
  originalWidth: number,
  originalHeight: number,
  aspect: number,
): ImageCropData => {
  const areaWidth = originalHeight * aspect;
  if (areaWidth <= originalWidth) {
    return {
      width: areaWidth,
      height: originalHeight,
      x: (originalWidth - areaWidth) / 2,
      y: 0,
    };
  }

  const areaHeight = originalWidth / aspect;
  return {
    width: originalWidth,
    height: areaHeight,
    x: 0,
    y: (originalHeight - areaHeight) / 2,
  };
};

/**
   * calculate view area for image to download
   * it must be within original area and have required aspect
   * @param width image width
   * @param height image height
   * @param aspect required aspect
   */
export const getImageRectForDownload = (
  width: number,
  height: number,
  aspect: number,
): ImageCropData => {
  let areaWidth = width;
  let areaHeight = height;

  if (width <= height) {
    areaHeight = width / aspect;
  } else {
    areaWidth = height * aspect;
  }

  return {
    width: areaWidth,
    height: areaHeight,
    x: (areaWidth - width) / 2,
    y: (areaHeight - height) / 2,
  };
};

export const getImageUrlWithSponsorOverlay = (
  src: string,
  moment: MomentResponse,
): string => {
  // https://mom-dev-api.momento.pics/api/image/cropped?key=Live_Moments/Momento_Stadium/testh[…]/1635419180088/facebook-profile-picture-frame-design.png
  if (moment.sponsorOverlay && moment.sponsorOverlayImage) {
    // const srcWithOverlay = `${src}&overlayKey=${moment.sponsorOverlay}`;
    // https://mom-dev-api.momento.pics/api/image/cropped?key=Live_Moments/Momento_Stadium/testhousing2/20211022/1634871689787_xxxxxxxxxxxxxxxxxxxxxx.jpg&oX=2180&oY=4250&width=1650&height=1600&overlayKey=sponsor_overlays/1635419180088/facebook-profile-picture-frame-design.png
    // const srcWithOverlay =
    // `${src}&overlayKey=sponsor_overlays/1635419180088/facebook-profile-picture-frame-design.png`;
    // const srcWithOverlay= `${src}&overlayKey=sponsor_overlays/1635540436349/Verizon%20Frame.png`;
    // `${src}&overlayKey=sponsor_overlays/1635451314094/Group+74.png`;
    const srcWithOverlay = src;
    return srcWithOverlay;
  }

  return src;
};
