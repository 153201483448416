import ReactDOM, { render } from "react-dom";
import { BrowserRouter, Router } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { configure } from "mobx";

import { createBrowserHistory } from "history";
import React from "react";
import GA4React from "ga-4-react";

import { App } from "./App";
import reportWebVitals from "./reportWebVitals";

const history = createBrowserHistory();
const ga4react = new GA4React("G-CNJ22J348V");

configure({
  enforceActions: "never",
});

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    margin: 0;
    min-height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-grow: 1;
  }

  * {
    box-sizing: border-box;
  }
`;

(async (_) => {
  await ga4react
    .initialize()
    .then((res) => console.log("Analytics Success."))
    .catch((err) => console.log("Analytics Failure."))
    .finally(() => {
      ReactDOM.render(
        <React.StrictMode>
          <BrowserRouter>
            <GlobalStyle />
            <App />
          </BrowserRouter>
        </React.StrictMode>,
        document.getElementById("root"),
      );
    });
})();

reportWebVitals();
