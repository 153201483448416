import { stringify } from "query-string";
import { instance } from "../instance";
import { IAxiosError } from "../interfaces";
import { getError } from "../utils";

import { GetTeamAdsByTeamRequest, IntervalGamePopulated, TeamAdsWithId } from "../../shared/interfaces";
import { PATH } from "../../shared/constants";

export const getGamesByDateRequest = async (
  date: number,
): Promise<IntervalGamePopulated[]> => {
  try {
    const response = await instance.get(`${PATH.API}/${PATH.GAME}?start=${date}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getRecentGamesRequest = async (
  date: number,
): Promise<IntervalGamePopulated[]> => {
  try {
    const response = await instance.get(`${PATH.API}/${PATH.GAME}?recentGames=true&start=${date}&archived=false`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getGameDataByIdRequest = async (gameId: string): Promise<IntervalGamePopulated> => {
  try {
    const response = await instance.get(`${PATH.API}/${PATH.GAME}/${gameId}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getTeamAdsByTeamId = async (teamId: string): Promise<TeamAdsWithId> => {
  try {
    const request: GetTeamAdsByTeamRequest = {
      teamId,
    };

    const stringified = stringify(request, {
      skipNull: true,
    });
    const response = await instance.get(`${PATH.API}/${PATH.TEAM_ADS_FOR_TEAM}?${stringified}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};
