import * as _ from "lodash";
import { BehaviorSubject } from "rxjs";
import { MomentResponse } from "../../shared/interfaces/moment";
import { MomentImageCache } from "./moment-image-cache";
import { CachedMomentImage } from "./cached-moment-image";

export class MomentImageCacheService {
  private momentImagesRefs: Array<any> = [];

  momentImageCaches: Array<MomentImageCache> = [];

  firstImages$ = new BehaviorSubject<HTMLImageElement[]>(null);

  remainingImages$ = new BehaviorSubject<HTMLImageElement[]>([]);

  gifImages$ = new BehaviorSubject<MomentImageCache[]>([]);
  // gifImages$ = new BehaviorSubject<string[]>([]);

  loading = false;

  generateGIFs = false;

  generateGIFForSharing = false;

  getCacheForMoment(momentId: string): MomentImageCache {
    return _.find(this.momentImageCaches, (c) => c.momentId === momentId);
  }

  cacheForIndex(index: number) {
    return _.find(this.momentImageCaches, (c) => c.index === index);
  }

  async preCacheMomentImages(momentList: Array<MomentResponse>, fromMoment?: number, toMoment?: number): Promise<void> {
    this.loading = true;
    this.loadMomentImageInventory(momentList, fromMoment, toMoment);
    if (!this.generateGIFs) {
      console.log("fetchFirstImages");
      await this.fetchFirstImages(true, true);
      console.log("fetch images for first three momentos");
      await this.fetchFirstImages(false, true);
      console.log("fetch the rest of the images");
      await this.fetchFirstImages(true, false);
      await this.fetchFirstImages(false, false);
    } else {
      console.log("fetchFirstImages");
      await this.fetchFirstImages(true, false);
      console.log("fetch the rest of the images");
      await this.fetchFirstImages(false, false);
    }
    this.loading = false;

    if (this.generateGIFs) {
      console.log("renderGifs()");
      return new Promise((resolve) => {
        // MMNT-163 It ain't right but it works
        // Images were for some reason coming in after the
        // Gifs were already trying to render...
        setTimeout(async () => {
          await this.renderGifs();
          resolve();
        }, 3000);
      });
    }

    return null;
  }

  async renderSingleGIF(momentId: string, forSharing?: boolean): Promise<string> {
    const momentImagesCache = this.momentImageCaches.find((cache) => cache.momentId === momentId);
    return new Promise((resolve) => {
      setTimeout(async () => {
        if (momentImagesCache) {
          await momentImagesCache.renderGif(forSharing);
          this.gifImages$.next([momentImagesCache]);
        }

        resolve(momentImagesCache?.gifImage?.src);
      }, 100);
    });
  }

  async renderSingleGifForSharing(momentId: string): Promise<string> {
    const momentImagesCache = this.momentImageCaches.find((cache) => cache.momentId === momentId);
    if (!momentImagesCache) {
      return null;
    }

    const gifImage = await momentImagesCache.renderGifForSharing();

    return gifImage?.src || null;
  }

  async renderAllGIFs(): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(async () => {
        await this.renderGifs();
        resolve();
      }, 100);
    });
  }

  async fetchFirstImages(fetchFirst: boolean, firstThreeMoments: boolean) {
    const promises: Promise<HTMLImageElement[]>[] = [];
    const momentCaches = firstThreeMoments
      ? this.firstThreeMomentCaches()
      : this.momentImageCaches;
    for (const cache of momentCaches) {
      promises.push(cache.fetchFirstImages(fetchFirst));
    }
    const resultPromise = Promise.all(promises);
    resultPromise.then((results) => {
      if (fetchFirst) {
        this.firstImages$.next(_.flatten(results));
      } else {
        this.remainingImages$.next(_.flatten(results));
      }
    });
    return resultPromise;
  }

  firstThreeMomentCaches() {
    return _.filter(this.momentImageCaches, (c) => {
      return c.index < 3;
    });
  }

  async renderGifs() {
    const promises: Promise<HTMLImageElement>[] = [];
    for (const cache of this.momentImageCaches) {
      promises.push(cache.renderGif());
    }
    const resultPromise = Promise.all(promises);
    resultPromise.then((results) => {
      this.gifImages$.next(_.flatten(this.momentImageCaches));
    });
    return resultPromise;
  }

  loadMomentImageInventory(momentList: Array<MomentResponse>, fromMoment?: number, toMoment?: number) {
    _.each(momentList, (momentResponse, momentListIndex) => {
      const skipPreload = (fromMoment !== undefined && momentListIndex < fromMoment)
        || (toMoment !== undefined && momentListIndex >= toMoment);

      if (!skipPreload) {
        let momentCache = this.getCacheForMoment(momentResponse._id);

        if (!momentCache) {
          momentCache = new MomentImageCache();
          this.momentImageCaches.push(momentCache);
        }

        momentCache.index = momentListIndex;
        momentCache.momentId = momentResponse._id;
        const links = [...momentResponse.momentoLinks];

        _.each(links.reverse(), (imageLink, momentIndex) => {
          let cachedImage = _.find(
            momentCache.momentImages,
            (i) => i.imageUrl === imageLink,
          );
          if (!cachedImage) {
            cachedImage = new CachedMomentImage();
            momentCache.momentImages.push(cachedImage);
          }
          cachedImage.index = momentIndex;
          cachedImage.imageUrl = imageLink;
        });

        if (momentResponse.sponsorOverlay && momentResponse.sponsorOverlayImage) {
          let overlayCachedImage = momentCache.sponsorOverlay;
          if (!overlayCachedImage) {
            overlayCachedImage = new CachedMomentImage();
            momentCache.sponsorOverlay = overlayCachedImage;
          }
          overlayCachedImage.index = -1;
          overlayCachedImage.imageUrl = momentResponse.sponsorOverlayImage;
        }
      }
    });
  }
}
