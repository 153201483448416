import { instance } from "../instance";
import { IAxiosError } from "../interfaces";
import { getError } from "../utils";

import { BasicResponse, UserWithGameDetails } from "../../shared/interfaces";
import { PATH } from "../../shared/constants";

export const addUserRequest = async (
  request: UserWithGameDetails,
): Promise<any[]> => {
  try {
    const response = await instance.post(`${PATH.API}/${PATH.USER}`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getLastSession = async (
  email: string,
): Promise<any> => {
  try {
    const response = await instance.get<any>(`${PATH.API}/${PATH.USER}/${PATH.GET_LAST_SESSION}?email=${email}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const updateLastSessionDate = async (
  _id: string,
): Promise<any> => {
  try {
    const response = await instance.put<any>(`${PATH.API}/${PATH.USER}/${PATH.UPDATE_USER_SESSION}/${_id}`, {
      session: {
        updated: new Date(),
      },
    });

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const payForTheGame = async (
  email: string, gameId: string,
): Promise<BasicResponse> => {
  try {
    const response = await instance.post<BasicResponse>(`${PATH.API}/${PATH.PAYMENT}/${PATH.ADD_UPDATE_PAYMENT}`, {
      email,
      gameId,
      paid: true,
    });

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const addPendingPayment = async (
  email: string, gameId: string,
): Promise<BasicResponse> => {
  try {
    const response = await instance.post<BasicResponse>(`${PATH.API}/${PATH.PAYMENT}/${PATH.ADD_PENDING_PAYMENT}`, {
      email,
      gameId,
      pending: true,
    });

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};
