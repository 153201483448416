import { lazy, Suspense, useEffect } from "react";
import { Switch, useHistory, useLocation } from "react-router-dom";

import { SuspenceFallbackComponent } from "./components/SuspenceFallback";
import { NavigationComponent } from "./components/Navigation";
import { CustomRoute } from "./components/CustomRoute";
import { Routes } from "./utils/routes";
import "./utils/browser";

import { AppServiceInstance } from "./services/app";
import "./services/moment";
import "./services/storage";
import "./services/game";

import "react-calendar/dist/Calendar.css";
import "./styles/main.scss";

const WelcomePage = lazy(() => import("./pages/welcome"));
const GamesPage = lazy(() => import("./pages/games"));
const SeatPage = lazy(() => import("./pages/seat"));
const FindPlacePage = lazy(() => import("./pages/place"));
const DetailsPage = lazy(() => import("./pages/userDetails"));
const MyMomentsPage = lazy(() => import("./pages/myMoments"));
const MomentPage = lazy(() => import("./pages/moment"));
const MomentoPage = lazy(() => import("./pages/momento"));
const AllMomentoPage = lazy(() => import("./pages/allMomento"));
const EditorPage = lazy(() => import("./pages/editor"));
const DownloadOptionsPage = lazy(() => import("./pages/downloadOptions"));
const ViewImagePage = lazy(() => import("./pages/viewImage"));
const signupPage = lazy(() => import("./pages/signup"));
const moreinfoPage = lazy(() => import("./pages/moreinfo"));
const momentoOptionsPage = lazy(() => import("./pages/momentoOptions"));
const verifySeatPage = lazy(() => import("./pages/verifySeat"));

export const App = (): JSX.Element => {
  const { pathname } = useLocation();
  useEffect(() => {
    AppServiceInstance.closeNavigationBar();
  }, [pathname]);

  const history = useHistory();
  useEffect(() => {
    AppServiceInstance.setHistory(history);
  }, []);

  return (
    <Suspense fallback={<SuspenceFallbackComponent />}>
      <NavigationComponent />
      <Switch>
        <CustomRoute exact path={Routes.WELCOME} Component={WelcomePage} />
        <CustomRoute exact path={Routes.GAMES} Component={GamesPage} />
        <CustomRoute exact path={Routes.SEAT} Component={SeatPage} />
        <CustomRoute exact path={Routes.PLACE} Component={FindPlacePage} />
        <CustomRoute exact path={Routes.DETAILS} Component={DetailsPage} />
        <CustomRoute exact path={Routes.MY_MOMENTS} Component={MyMomentsPage} />
        <CustomRoute exact path={Routes.MOMENT} Component={MomentPage} />
        <CustomRoute exact path={Routes.MOMENTO} Component={MomentoPage} />
        <CustomRoute exact path={Routes.ALL_MOMENTO} Component={AllMomentoPage} />
        <CustomRoute exact path={Routes.EDITOR} Component={EditorPage} />
        <CustomRoute exact path={Routes.DOWNLOAD_OPTIONS} Component={DownloadOptionsPage} />
        <CustomRoute exact path={Routes.IMAGE} Component={ViewImagePage} />
        <CustomRoute exact path={Routes.SIGNUP} Component={signupPage} />
        <CustomRoute exact path={Routes.MOREINFO} Component={moreinfoPage} />
        <CustomRoute exact path={Routes.MOMENTO_OPTIONS} Component={momentoOptionsPage} />
        <CustomRoute exact path={Routes.VERIFY_SEAT} Component={verifySeatPage} />

      </Switch>
    </Suspense>
  );
};
