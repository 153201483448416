import { observable, makeAutoObservable, reaction, action } from "mobx";
import { createContext } from "react";

import { getGamesByDateRequest, getRecentGamesRequest } from "../axios/routes/game";
import { IntervalGamePopulated } from "../shared/interfaces";
import { MAX_GAME_DURATION } from "../utils/constants";
import { showErrorNotification } from "../utils/notification";

export type GameType = "all"|"live"|"finished"|"future";

class GameService {
  private games: IntervalGamePopulated[] = [];

  @observable pastGames: IntervalGamePopulated[] = [];

  @observable futureGames: IntervalGamePopulated[] = [];

  @observable liveGames: IntervalGamePopulated[] = [];

  @observable allGames: IntervalGamePopulated[] = [];

  @observable selectedDate: Date = null;

  @observable dateToFetchRecentGames: Date = null;

  constructor() {
    makeAutoObservable(this);

    console.log("> Fetching recent games");
    this.fetchRecentGames(new Date());

    reaction(() => this.games, () => this.sortResentGames());
  }

  fetchGamesForDate = async (date: Date) => {
    if (this.selectedDate && this.selectedDate.getTime() === date.getTime()) {
      console.log("> The games for this day are already fetched");
      return;
    }

    this.selectedDate = date;
    try {
      this.games = await getGamesByDateRequest(date.setHours(0, 0, 0, 0));
      this.sortGamesForDate();
    } catch (err) {
      showErrorNotification(err.message);
    }
  }

  fetchRecentGames = async (date: Date) => {
    if (this.dateToFetchRecentGames && this.dateToFetchRecentGames.getTime() === date.getTime()) {
      console.log("> Recent games are already fetched");
      return;
    }

    this.dateToFetchRecentGames = date;
    try {
      this.games = await getRecentGamesRequest(date.getTime());

      const hostnamePrefix = window.location.hostname.substring(0, window.location.hostname.indexOf(".")).toLowerCase();
      // const gamesFilteredByTeamName = this.games.filter((game) => ((game.team1.name.toLowerCase() === hostnamePrefix) || (game.team2.name.toLowerCase() === hostnamePrefix)));

      const gamesFilteredByTeamName = [];
      this.games.forEach((game) => {
        // Team names are like "Kansas City Chiefs", but the domain name prefix will be like 'chiefs'
        const teamNames = [game.team1.name.toLowerCase(), game.team2.name.toLowerCase()];
        for (let i = 0; i < 2; i += 1) {
          const splitTeamName = teamNames[i].split(" ");
          teamNames[i] = splitTeamName[splitTeamName.length - 1];
        }
        if ((teamNames[0] === hostnamePrefix) || (teamNames[1] === hostnamePrefix)) {
          gamesFilteredByTeamName.push(game);
        }
      });

      if (gamesFilteredByTeamName.length > 0) {
        this.games = gamesFilteredByTeamName;
      }

      this.sortResentGames();
    } catch (err) {
      showErrorNotification(err.message);
    }
  }

  @action sortGamesForDate = () => {
    this.pastGames = [];
    this.futureGames = [];
    this.liveGames = [];

    const currentTime = new Date().getTime();

    this.games.forEach((game) => {
      if (game.finishDate || game.startDate < currentTime - MAX_GAME_DURATION) {
        this.pastGames.push(game);
      } else if (game.startDate < currentTime) {
        this.liveGames.push(game);
      } else {
        this.futureGames.push(game);
      }
    });

    this.allGames = [...this.futureGames, ...this.liveGames, ...this.pastGames];
  }

  @action sortResentGames = () => {
    this.pastGames = [];
    this.futureGames = [];
    this.liveGames = [];

    const currentTime = new Date().getTime();

    this.games.forEach((game) => {
      if (game.finishDate && game.finishDate < currentTime) {
        this.pastGames.push(game);
      } else if (game.startDate <= currentTime) {
        this.liveGames.push(game);
      } else {
        this.futureGames.push(game);
      }
    });

    this.allGames = [...this.liveGames, ...this.futureGames, ...this.pastGames];
  }
}

export const GameServiceInstance = new GameService();

export const GameServiceContext = createContext(GameServiceInstance);
