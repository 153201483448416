const extractCommand = "____extract";
const overlayCommand = "____overlay";

const applyCrop = (url: string, x, y, w, h): string => {
  if (!url) {
    return "";
  }

  const commandStart = url.indexOf(extractCommand);
  if (commandStart === -1) {
    return url;
  }

  const commandEndFirst = url.indexOf("?", commandStart + extractCommand.length);
  const commandEndSecond = url.indexOf("_", commandStart + extractCommand.length);
  const commandEnd = commandEndFirst < commandEndSecond ? commandEndFirst : commandEndSecond;

  if (commandEnd === -1) {
    return url;
  }

  const start = url.substring(0, commandStart + extractCommand.length);
  const end = url.substring(commandEnd, url.length);

  const newUrl = `${start}~${x}~${y}~${w}~${h}${end}`;

  return newUrl;
};

const removeOverlay = (url: string): string => {
  if (!url) {
    return "";
  }

  const commandStart = url.indexOf(overlayCommand);
  if (commandStart === -1) {
    return url;
  }

  const commandEndFirst = url.indexOf("?", commandStart + overlayCommand.length);
  const commandEndSecond = url.indexOf("_", commandStart + overlayCommand.length);
  const commandEnd = commandEndFirst < commandEndSecond ? commandEndFirst : commandEndSecond;
  if (commandEnd === -1) {
    return url;
  }

  const start = url.substring(0, commandStart);
  const end = url.substring(commandEnd, url.length);

  const newUrl = `${start}${end}`;

  return newUrl;
};

export class CachedMomentImage {
  index: number;

  imageUrl: string;

  imageElement: HTMLImageElement;

  imagePromise: Promise<HTMLImageElement>;

  fetchInProgress = false;

  fetchComplete = false;

  async fetchImage(): Promise<HTMLImageElement> {
    console.log(`*** START fetchImage(${this.imageUrl})`);
    // const croppedUrl = applyCrop(this.imageUrl, 2180, 4250, 165, 160);
    // const croppedUrl = removeOverlay(this.imageUrl);
    const croppedUrl = this.imageUrl;

    this.fetchInProgress = true;
    this.imagePromise = this.fetchImageAsync(croppedUrl);
    this.imagePromise.then((image) => {
      this.imageElement = image;
      this.fetchInProgress = false;
      this.fetchComplete = true;
      console.log(`*** FINISH fetchImage(${this.imageUrl})`);
    });
    return this.imagePromise;
  }

  // eslint-disable-next-line class-methods-use-this
  fetchImageAsync(source: string) {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const image = new Image();

      /**
       * Listener for `load` event.
       * @param event
       */
      const onLoad = (event) => {
        // eslint-disable-next-line no-use-before-define
        clean(event);
        resolve(image);
      };

      /**
       * Remove listeners for `load` & `error` events and prevents its propagations.
       * @param event
       */
      const clean = (event) => {
        event.preventDefault();
        event.stopPropagation();
        // eslint-disable-next-line no-use-before-define
        image.removeEventListener("load", onLoad);
        // eslint-disable-next-line no-use-before-define
        image.removeEventListener("error", onError);
      };

      /**
       * Listener for `error` event.
       * @param event
       */

      const onError = (event) => {
        clean(event);
        reject(new Error(event.message));
      };

      image.addEventListener("load", onLoad);
      image.addEventListener("error", onError);
      image.crossOrigin = "Anonymous";
      image.src = source;
    });
  }
}
