import GIF from "gif.js.optimized";
import { message } from "antd";
import { IntervalGamePopulated, MomentResponse } from "../shared/interfaces";
import { ImageCropData, MomentHeaderData, releaseCanvas, renderSponsorOverlay, renderMomentHeader } from "./sharedFunctions";

const blobToDataURL = async (blob) => {
  return new Promise<string>((resolve) => {
    const a = new FileReader();
    a.onload = (e) => {
      resolve(e.target.result as string);
    };
    a.readAsDataURL(blob);
  });
};

// MMNT-163 @ram Try this and let me know what you think...
export const renderAnimatedGifsFromCanvas = async (
  width: number,
  height: number,
  canvasWithImages: HTMLCanvasElement[],
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const gif = new GIF({
      workers: 2,
      quality: 10,
      width,
      height,
      copy: true,
    });

    for (const canvasWithImage of canvasWithImages) {
      gif.addFrame(canvasWithImage, { delay: 200 });
    }

    gif.on("finished", (blob) => {
      const url = blobToDataURL(blob);
      resolve(url);
    });

    gif.on("error", () => {
      message.error("Something went wrong while creating a GIF for download");
      reject(null);
    });

    gif.render();
  });
};

// use gif JS package to create GIF
export const downloadGifImageGifJS = async (
  width: number,
  height: number,
  canvasWithImages: HTMLCanvasElement[],
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const gif = new GIF({
      workers: 2,
      quality: 10,
      width,
      height,
      copy: true,
    });

    for (let ind = 0; ind < canvasWithImages.length; ind += 1) {
      gif.addFrame(canvasWithImages[ind], { delay: 200 });
    }
    gif.on("finished", (blob) => {
      const url = URL.createObjectURL(blob);
      resolve(url);
    });

    gif.on("error", () => {
      message.error("Something went wrong while creating a GIF for download");
      reject(null);
    });

    gif.render();
  });
};

// preparations for GIF creation
const convertLoadedImageToImageCanvas = (
  cropData: ImageCropData,
  canvasWidth: number, canvasHeight: number,
  img: HTMLImageElement, sponsorImage?: HTMLImageElement,
  headerData?: MomentHeaderData,
  forSharing?: boolean,
): HTMLCanvasElement => {
  console.log(`cropData: ${cropData}`);
  const { x, y, width, height } = cropData;

  const canvas = document.createElement("canvas");
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  const context = canvas.getContext("2d");

  if (!context) {
    console.log("No memory for canvas");
    return canvas;
  }

  // scale all images to the same area with first image sizes
  const ratioX = width / canvasWidth;
  const ratioY = height / canvasHeight;
  const ratio = ratioX > ratioY ? ratioX : ratioY;
  const drawWidth = width / ratio;
  const drawHeight = height / ratio;
  const offsetX = (canvasWidth - drawWidth) / 2;
  const offsetY = (canvasHeight - drawHeight) / 2;

  context.drawImage(img, x, y, width, height,
    offsetX, offsetY, drawWidth, drawHeight);

  if (sponsorImage) {
    // render overlay with initial image sizes
    renderSponsorOverlay(
      context,
      sponsorImage,
      0,
      0,
      canvasWidth,
      canvasHeight,
    );
  }

  if (headerData) {
    renderMomentHeader(
      context,
      canvasWidth,
      headerData,
      forSharing,
    );
  }

  return canvas;
};

// prepare GIF for playing
export const prepareGifForPlayForLoadedImages = async (
  images: HTMLImageElement[],
  positions: ImageCropData[],
  sponsorImage?: HTMLImageElement,
  headerData?: MomentHeaderData,
): Promise<string | null> => {
  console.log("prepareGifForPlayForLoadedImages()");
  try {
    const { width, height } = positions[0];
    const canvasWithImages = images.map((image, index) => {
      return convertLoadedImageToImageCanvas(
        positions[0], width, height, image, sponsorImage,
        // headerData,
      );
    });

    // MMNT-163 @ram Adjust this so that it does not download the images
    const gifImage = await downloadGifImageGifJS(
      width, height, canvasWithImages,
    );

    for (let ind = 0, len = canvasWithImages.length; ind < len; ind += 1) {
      releaseCanvas(canvasWithImages[ind]);
    }

    return gifImage;
  } catch (err) {
    console.error(err);
    message.error("Something went wrong while creating a GIF");

    return null;
  }
};

// prepare GIF for playing
export const buildAnimatedGifFromImageElements = async (
  images: HTMLImageElement[],
  sizes: ImageCropData,
  positions: ImageCropData[],
  sponsorImage?: HTMLImageElement,
  headerData?: MomentHeaderData,
  forSharing?: boolean,
): Promise<string | null> => {
  console.log("buildAnimatedGifFromImageElements():", images.map((i) => i?.src));
  try {
    const { width, height } = sizes;
    // delete undefined images
    const existingImages = images.filter((image) => image);
    const canvasWithImages = existingImages.map((image, index) => {
      const position = index < positions.length ? positions[index] : sizes;
      return convertLoadedImageToImageCanvas(
        position, width, height, image, sponsorImage,
        // headerData,
        undefined, forSharing,
      );
    });

    // MMNT-163 @ram Adjust this so that it does not download the images
    const gifImage = await renderAnimatedGifsFromCanvas(
      width, height, canvasWithImages,
    );

    for (let ind = 0, len = canvasWithImages.length; ind < len; ind += 1) {
      releaseCanvas(canvasWithImages[ind]);
    }

    return gifImage;
  } catch (err) {
    console.error(err);
    message.error("Something went wrong while creating a GIF");

    return null;
  }
};
