import * as _ from "lodash";
import { CachedMomentImage } from "./cached-moment-image";
import { buildAnimatedGifFromImageElements } from "../../utils/gif";
import { concurrent } from "../../utils/limit";
import { StorageServiceInstance } from "../storage";
import { ImageCropData } from "../../utils/sharedFunctions";

export class MomentImageCache {
  index: number;

  momentId: string;

  momentImages: Array<CachedMomentImage> = [];

  sponsorOverlay: CachedMomentImage = null;

  gifImage: HTMLImageElement;

  firstImagePromises: Promise<HTMLImageElement[]>;

  remainingImagePromises: Promise<HTMLImageElement[]>;

  gifImagePromise: Promise<HTMLImageElement>;

  width = 1650;

  height = 1650;

  gifRendering = false;

  gifQueue = false;

  firstImages() {
    return _.filter(this.momentImages, (i) => {
      return i.index === 0;
    });
  }

  remainingImages() {
    return _.filter(this.momentImages, (i) => {
      return i.index !== 0;
    });
  }

  allImages() {
    return this.momentImages.map((i) => i.imageElement);
  }

  fetchFirstImages(fetchFirst: boolean): Promise<HTMLImageElement[]> {
    console.log(`fetchFirstImages(${fetchFirst})`);
    const cacheImages = fetchFirst
      ? this.firstImages()
      : this.remainingImages();
    const limited = [];
    console.log(`cacheImages: ${cacheImages.length}`);
    for (const cacheImage of cacheImages) {
      // Let's not start to fetch images until
      limited.push(() => cacheImage.fetchImage());
    }

    // retreive sponsor overlays with first images
    if (fetchFirst && this.sponsorOverlay) {
      limited.push(() => this.sponsorOverlay.fetchImage());
    }

    this.firstImagePromises = concurrent(limited, 7);

    return this.firstImagePromises;
  }

  getImagePosition(momentoIndex: number) {
    const savedCropData = StorageServiceInstance.getImageCropData(
      this.momentId,
      momentoIndex,
    );

    if (savedCropData) {
      return {
        x: savedCropData.x,
        y: savedCropData.y,
        width: savedCropData.width,
        height: savedCropData.height,
      };
    }

    return {
      x: StorageServiceInstance.centerCoords.x - StorageServiceInstance.defaultZoomWidth / 2,
      y: StorageServiceInstance.centerCoords.y - StorageServiceInstance.defaultZoomHeight / 2 - StorageServiceInstance.offsetY,
      width: StorageServiceInstance.defaultZoomWidth,
      height: StorageServiceInstance.defaultZoomHeight,
    };
  }

  getAllImagesPositions() {
    const length = this.momentImages.length - 1;
    return this.momentImages.map((i, index) => this.getImagePosition(length - index));
  }

  renderGif(forSharing?: boolean): Promise<HTMLImageElement> {
    if (!this.gifRendering) {
      this.gifRendering = true;
      this.gifImage = null;
      this.gifImagePromise = new Promise((resolve) => {
        const allImages = this.allImages();
        console.log("renderGif()", allImages[0]?.width, allImages[0], this.getAllImagesPositions());
        buildAnimatedGifFromImageElements(
          this.allImages(),
          { x: 0, y: 0, width: this.width, height: this.height } as ImageCropData,
          this.getAllImagesPositions(),
          this.sponsorOverlay?.imageElement || null,
          undefined,
          forSharing,
        ).then((urlString) => {
          this.gifImage = new Image();
          this.gifImage.src = urlString;
          resolve(this.gifImage);
          this.gifRendering = false;
        });
      });
    } else if (!this.gifQueue) {
      // recreate gif with new crop-images after current gif creation
      this.gifQueue = true;
      return this.gifImagePromise.then(() => {
        this.gifQueue = false;
        return this.renderGif(forSharing);
      });
    }

    return this.gifImagePromise;
  }

  // more straigthforward logic
  async renderGifForSharing(): Promise<HTMLImageElement> {
    const urlString = await buildAnimatedGifFromImageElements(
      this.allImages(),
      { x: 0, y: 0, width: this.width, height: this.height } as ImageCropData,
      this.getAllImagesPositions(),
      this.sponsorOverlay?.imageElement || null,
      undefined,
      true,
    );

    if (!urlString) {
      return null;
    }

    const gifImage = new Image();
    gifImage.src = urlString;
    return gifImage;
  }
}
