import axios from "axios";
import { format } from "date-fns";
// import "context-filter-polyfill";
// this function uses different routes for client and operator
import { prepareUploadLargeMOTGImage } from "../axios/routes/moment";
import { MomentResponse } from "../shared/interfaces/moment";
import { IntervalGamePopulated } from "../shared/interfaces";

// TODO: use the same functions both in client and in client-operator

export enum ASPECT_RATIO_LIST {
  "9_16" = 9 / 16,
  "3_4" = 3 / 4,
  "6_4" = 6 / 4,
  "16_9" = 16 / 9,
  "1_1" = 1,
}

export interface ImageCoordinates {
  x: number;
  y: number;
}

export interface ImageSize {
  width: number;
  height: number;
}

export interface ImageCropData extends ImageCoordinates, ImageSize {
  aspect?: ASPECT_RATIO_LIST;
}

export const calculateImageStartPosition = (
  cropData: ImageCropData | null,
  centerCoordsX: number,
  centerCoordsY: number,
  defaultZoomWidth: number,
  defaultZoomHeight: number,
  offsetTop: number,
): ImageCoordinates => {
  if (cropData) {
    return {
      x: cropData.x,
      y: cropData.y,
    };
  }

  return {
    x: centerCoordsX - defaultZoomWidth / 2,
    y: centerCoordsY - defaultZoomHeight / 2 - offsetTop,
  };
};

export const calculateImageSize = (
  cropData: ImageCropData | null,
  defaultZoomWidth: number,
  defaultZoomHeight: number,
): ImageSize => {
  if (cropData) {
    return {
      width: cropData.width,
      height: cropData.height,
    };
  }

  return {
    width: defaultZoomWidth,
    height: defaultZoomHeight,
  };
};

export interface MomentHeaderData {
  moment?: MomentResponse;
  selectedGameData?: IntervalGamePopulated;
  team1LogoImage?: HTMLImageElement;
  team2LogoImage?: HTMLImageElement;
  logoImage?: HTMLImageElement;
  team1Color?: string;
  team2Color?: string;

  minWidth?: number; // for downloading
  maxWidth?: number; // for rendering
  hideScoreboard?: boolean; // allow to hide scoreboard. It overwrites moment's flag
  hideCaption?: boolean;
  caption?: string;
}

// try to reduce canvas memory: Safari issue with 384Mb for all canvas
export const releaseCanvas = (canvas: HTMLCanvasElement): void => {
  if (canvas) {
    canvas.width = 1;
    canvas.height = 1;
    const context = canvas.getContext("2d");
    if (context) {
      context.clearRect(0, 0, 1, 1);
    }
  }
};

/**
 * Render overlay over image
 * @param context - canvas context to render on
 * @param sponsorImage - overlay image
 * @param canvasXOffset - x offset
 * @param canvasYOffset - y offset
 * @param canvasWidth - area width
 * @param canvasHeight - area height
 */
export const renderSponsorOverlay = (
  context: CanvasRenderingContext2D,
  sponsorImage: HTMLImageElement,
  canvasXOffset: number,
  canvasYOffset: number,
  canvasWidth: number,
  canvasHeight: number,
): void => {
  const width = canvasWidth; //  - canvasXOffset * 2;
  const height = canvasHeight; //  - canvasYOffset * 2;
  const sponsorImageAspect = sponsorImage.height / sponsorImage.width;

  let newWidth = canvasWidth;
  let newHeight = newWidth * sponsorImageAspect;
  if (newHeight > height) {
    const ratio = height / newHeight;
    newHeight *= ratio;
    newWidth *= ratio;
  }

  const newX = canvasXOffset + (width - newWidth) / 2.0;

  if (sponsorImage.width > sponsorImage.height) {
    const newY = canvasYOffset + height - newHeight;

    context.drawImage(
      sponsorImage,
      newX,
      newY,
      newWidth,
      newHeight,
    );

    return;
  }

  // cut overlay on 2 parts and draw them aligned to top/bottom borders
  const halfHeight = newHeight / 2.0;
  context.drawImage(
    sponsorImage,
    0,
    0,
    sponsorImage.width,
    sponsorImage.height / 2.0,
    newX,
    canvasYOffset,
    newWidth,
    halfHeight,
  );

  context.drawImage(
    sponsorImage,
    0,
    sponsorImage.height / 2.0,
    sponsorImage.width,
    sponsorImage.height / 2.0,
    newX,
    canvasYOffset + height - halfHeight,
    newWidth,
    halfHeight,
  );
};

export const COLORS_PARTIAL = {
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  GREY_DARK: "#252847",
  GREEN_DARK: "#02653B",
};

export enum FontFamilyTypeEnum_PARTIAL {
  POPPINS = "Poppins",
  Copperplate = "Copperplate",
  Seahawks = "Seahawks",
  SFPRO = "SFPRO",
  Mariners = "Mariners",
}

const renderRoundedBox = (
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  w: number,
  h: number,
  r: number,
  color: string,
) => {
  context.beginPath();
  context.fillStyle = color;
  context.strokeStyle = color;
  context.moveTo(x, y + r);
  context.lineTo(x, y + h - r);
  context.arcTo(x, y + h, x + r, y + h, r);
  context.lineTo(x + w - r, y + h);
  context.arcTo(x + w, y + h, x + w, y + h - r, r);
  context.lineTo(x + w, y + r);
  context.arcTo(x + w, y, x + w - r, y, r);
  context.lineTo(x + r, y);
  context.arcTo(x, y, x, y + r, r);
  context.stroke();
  context.fill();
  context.closePath();
};

/**
 * Render moment header over image
 * @param context - canvas context to render on
 * @param canvasWidth - area width
 * @param headerData - header information
 * @param scaleToImageSize - scale header to image proportions (share / download)
 */
export const renderMomentHeader = (
  context: CanvasRenderingContext2D,
  canvasWidth: number,
  headerData: MomentHeaderData,
  scaleToImageSize?: boolean,
  skipLogos = false,
): void => {
  context.imageSmoothingEnabled = true;
  context.imageSmoothingQuality = "high";

  const IMAGE_WIDTH_PART = 0.8;
  const scale = !scaleToImageSize ? 1 : (canvasWidth * IMAGE_WIDTH_PART) / 300;

  const { maxWidth } = headerData;
  const headerWidth = maxWidth && canvasWidth > maxWidth ? maxWidth : canvasWidth;
  const topBoxLeftX = (canvasWidth - headerWidth) / 2;

  const topBoxHeight = 33 * scale;
  context.fillStyle = headerData.team1Color || COLORS_PARTIAL.GREEN_DARK;
  context.strokeStyle = headerData.team1Color || COLORS_PARTIAL.WHITE;
  context.beginPath();
  context.rect(0, 0, canvasWidth, topBoxHeight);
  context.fill();
  context.closePath();

  const headerText = headerData.moment.text || "Default Header Text";

  if (headerText) {
    context.beginPath();

    context.fillStyle = COLORS_PARTIAL.WHITE;
    let fontSize = 22 * scale * 0.85; // Reduced font size by 15%
    context.font = `700 ${fontSize}px ${FontFamilyTypeEnum_PARTIAL.Copperplate}`;
    let measurementsFirstLine = context.measureText(headerText);
    let textWidth = measurementsFirstLine.width;

    // Adjust available space by considering the white background box on the right
    const whiteBackgroundX = canvasWidth - 200 * scale; // Adjust as necessary
    const availableSpace = whiteBackgroundX - 5 * scale;

    // Reduce font size if text width exceeds available space
    while (textWidth > availableSpace && fontSize > 8) {
      fontSize *= 0.95;
      context.font = `700 ${fontSize}px ${FontFamilyTypeEnum_PARTIAL.Copperplate}`;
      measurementsFirstLine = context.measureText(headerText);
      textWidth = measurementsFirstLine.width;
    }

    context.fillText(headerText, 10 * scale, 24 * scale);
    context.closePath();
  }

  const sport = headerData.selectedGameData?.sportName;
  const isOtherSport = sport && sport.startsWith("Other");
  if (isOtherSport || headerData.hideScoreboard) {
    return;
  }

  if (headerData.moment.team1Score !== null && headerData.moment.team2Score !== null) {
    const logoSize = 28 * scale;
    const padding = 5 * scale; // Padding to extend a little bit past the logos

    // Calculate the position and size of the white background
    const whiteBackgroundHeight = topBoxHeight;
    const extraPaddingLeft = 10 * scale; // Additional padding to the left of team1 logo
    const whiteBackgroundX = canvasWidth - (2 * logoSize + padding * 4 + headerData.moment.team1Score.toString().length * 8 * scale + headerData.moment.team2Score.toString().length * 8 * scale + padding * 4) - extraPaddingLeft;
    const whiteBackgroundWidth = canvasWidth - whiteBackgroundX;
    const whiteBackgroundY = 0;

    context.beginPath();
    context.fillStyle = COLORS_PARTIAL.WHITE; // White background for scores and logos
    context.strokeStyle = "transparent"; // No border
    context.rect(whiteBackgroundX - extraPaddingLeft, whiteBackgroundY, whiteBackgroundWidth + extraPaddingLeft, whiteBackgroundHeight);
    context.fill();
    context.stroke();
    context.closePath();

    context.beginPath();
    context.fillStyle = COLORS_PARTIAL.BLACK;
    const scoreFontSize = 16 * scale * 1.1; // Increased font size by 10%
    context.font = `600 ${scoreFontSize}px ${FontFamilyTypeEnum_PARTIAL.SFPRO}`;

    const team1Score = `${headerData.moment.team1Score}`;
    const team2Score = `${headerData.moment.team2Score}`;
    const scoreText = `${team1Score} - ${team2Score}`;
    const measurementsScore = context.measureText(scoreText);
    const scoreTextWidth = measurementsScore.width;

    const totalWidth = scoreTextWidth + logoSize * 2 + padding * 4;
    const startX = canvasWidth - totalWidth - padding; // Start from the right side

    const team1LogoX = startX;
    const scoreX = team1LogoX + logoSize + padding;
    const team2LogoX = scoreX + scoreTextWidth + padding;

    const verticalCenter = whiteBackgroundHeight / 2;

    if (!skipLogos && headerData.team1LogoImage && headerData.team1LogoImage.width) {
      context.drawImage(headerData.team1LogoImage, team1LogoX, verticalCenter - logoSize / 2, logoSize, logoSize);
    }

    if (!skipLogos && headerData.team2LogoImage && headerData.team2LogoImage.width) {
      context.drawImage(headerData.team2LogoImage, team2LogoX, verticalCenter - logoSize / 2, logoSize, logoSize);
    }

    const scoreY = verticalCenter + (scoreFontSize / 2) - 4 * scale; // Center the score vertically with the logos
    context.fillText(scoreText, scoreX, scoreY);
    context.closePath();
  }
};

export interface LogosPositions {
  visible?: boolean;
  x1?: number;
  y1?: number;
  w1?: number;
  h1?: number;
  x2?: number;
  y2?: number;
  w2?: number;
  h2?: number;
}

export const getScoreAreaWidth = (
  context: CanvasRenderingContext2D,
  canvasWidth: number,
  headerData: MomentHeaderData,
  scaleToImageSize?: boolean,
) : LogosPositions => {
  const IMAGE_WIDTH_PART = 0.8;
  const scale = !scaleToImageSize ? 1 : (canvasWidth * IMAGE_WIDTH_PART) / 300;

  const { maxWidth } = headerData;
  const headerWidth = maxWidth && canvasWidth > maxWidth ? maxWidth : canvasWidth;

  if (headerData.moment.team1Score === null || headerData.moment.team2Score === null) {
    return {
      visible: false,
    };
  }

  const logoSize = 28 * scale;
  const padding = 5 * scale; // Padding to extend a little bit past the logos

  context.fillStyle = COLORS_PARTIAL.BLACK;
  const scoreFontSize = 16 * scale * 1.1; // Increased font size by 10%
  context.font = `600 ${scoreFontSize}px ${FontFamilyTypeEnum_PARTIAL.SFPRO}`;

  const team1Score = `${headerData.moment.team1Score}`;
  const team2Score = `${headerData.moment.team2Score}`;
  const scoreText = `${team1Score} - ${team2Score}`;
  const measurementsScore = context.measureText(scoreText);
  const scoreTextWidth = measurementsScore.width;

  const totalWidth = scoreTextWidth + logoSize * 2 + padding * 4;
  const startX = canvasWidth - totalWidth - padding; // Start from the right side

  const team1LogoX = startX;
  const scoreX = team1LogoX + logoSize + padding;
  const team2LogoX = scoreX + scoreTextWidth + padding;

  const whiteBackgroundY = 0;
  const topBoxHeight = 33 * scale;
  const whiteBackgroundHeight = topBoxHeight;
  const verticalCenter = whiteBackgroundHeight / 2;

  return {
    visible: true,
    x1: team1LogoX,
    y1: verticalCenter - logoSize / 2,
    w1: logoSize,
    h1: logoSize,
    x2: team2LogoX,
    y2: verticalCenter - logoSize / 2,
    w2: logoSize,
    h2: logoSize,
  };
};

export interface DownloadData extends ImageSize, ImageCoordinates {
  link: string;
  name: string;
}

export const convertToBase64 = (
  downloadData: DownloadData,
  sponsorImage?: HTMLImageElement,
  headerData?: MomentHeaderData,
  blurImage?: boolean,
): Promise<string> | null => {
  const { x, y, height, width, link } = downloadData;
  const canvasWidth = headerData && width < headerData.minWidth ? headerData.minWidth : width;

  const img = new Image();
  img.crossOrigin = "anonymous";
  img.src = link;

  const canvas = document.createElement("canvas");
  const headerHeight = 75; // Fixed height for the header
  canvas.width = canvasWidth;
  canvas.height = height + headerHeight + 10; // Add some extra space for padding
  const context = canvas.getContext("2d");

  if (!context) {
    console.log("No memory for canvas");
    return null;
  }

  return new Promise((resolve, reject) => {
    img.onload = () => {
      // Draw the header
      if (headerData) {
        renderMomentHeader(context, canvasWidth, headerData, true);
      }

      // Draw the image below the header
      if (blurImage) {
        context.filter = "blur(5px)";
        const canvasHeight = canvas.height - headerHeight - 10;
        context.drawImage(
          img,
          x,
          y,
          width,
          height,
          (canvasWidth - width) / 2,
          headerHeight + 10, // Position the image below the header with padding
          width,
          canvasHeight,
        );
        context.filter = "none";
        context.drawImage(
          img,
          x,
          y + height * 0.25,
          width,
          height * 0.5,
          (canvasWidth - width) / 2,
          headerHeight + 10 + canvasHeight * 0.25, // Position the image below the header with padding
          width,
          canvasHeight * 0.5,
        );
      } else {
        context.drawImage(
          img,
          x,
          y,
          width,
          height,
          (canvasWidth - width) / 2,
          headerHeight + 10, // Position the image below the header with padding
          width,
          canvas.height - headerHeight - 10,
        );
      }

      if (sponsorImage) {
        renderSponsorOverlay(
          context,
          sponsorImage,
          (canvasWidth - width) / 2,
          headerHeight + 10,
          width,
          canvas.height - headerHeight - 10,
        );
      }

      const dataUrl = canvas.toDataURL("image/png");

      // const blob = await new Promise((blobResolve) => canvas.toBlob(blobResolve));

      releaseCanvas(canvas);

      resolve(dataUrl);
    };
    img.onerror = reject;
  });
};

export const originalConvertToBase64 = (
  downloadData: DownloadData,
  sponsorImage?: HTMLImageElement,
  headerData?: MomentHeaderData,
  blurImage?: boolean,
): Promise<string> | null => {
  const { x, y, height, width, link } = downloadData;
  const canvasWidth = headerData && width < headerData.minWidth ? headerData.minWidth : width;

  const img = new Image();
  img.crossOrigin = "anonymous";
  img.src = link;

  const canvas = document.createElement("canvas");
  canvas.width = canvasWidth;
  canvas.height = height;
  const context = canvas.getContext("2d");

  if (!context) {
    console.log("No memory for canvas");
    return null;
  }

  return new Promise((resolve, reject) => {
    img.onload = () => {
      const canvasHeight = canvas.height;
      if (blurImage) {
        context.filter = "blur(5px)";
        context.drawImage(
          img,
          x,
          y,
          width,
          height,
          (canvasWidth - width) / 2,
          0,
          width,
          canvasHeight,
        );
        context.filter = "none";
        context.drawImage(
          img,
          x,
          y + 0.25 * height,
          width,
          height * 0.5,
          (canvasWidth - width) / 2,
          canvasHeight * 0.25,
          width,
          canvasHeight * 0.5,
        );
      } else {
        context.drawImage(
          img,
          x,
          y,
          width,
          height,
          (canvasWidth - width) / 2,
          0,
          width,
          canvasHeight,
        );
      }

      if (sponsorImage) {
        renderSponsorOverlay(
          context,
          sponsorImage,
          (canvasWidth - width) / 2,
          0,
          width,
          canvasHeight,
        );
      }

      if (headerData) {
        renderMomentHeader(context, canvasWidth, headerData, true);
      }

      const dataUrl = canvas.toDataURL("image/png");

      // const blob = await new Promise((blobResolve) => canvas.toBlob(blobResolve));

      releaseCanvas(canvas);

      resolve(dataUrl);
    };
    img.onerror = reject;
  });
};

export const urltoFile = (
  url: string, filename: string, mimeType: string, withDatePrefix = true,
): Promise<File> => {
  const date = new Date();

  const filePrefix = `${date.getFullYear()}-${(`0${date.getMonth() + 1}`).slice(-2)}-${(`0${date.getDate()}`).slice(-2)}-${(`0${date.getHours()}`).slice(-2)}-${(`0${date.getMinutes()}`).slice(-2)}-${(`0${date.getSeconds()}`).slice(-2)}`;

  const fileNameWithPrefix = withDatePrefix ? `${filePrefix}-${filename}` : filename;

  return fetch(url)
    .then((res) => {
      return res.arrayBuffer();
    })
    .then((buf) => {
      return new File([buf], fileNameWithPrefix, { type: mimeType });
    });
};

// upload any image to S3
export const uploadToS3ServerLargeImage = async (
  image: File,
  name: string,
  mimeType: string,
  s3Folder?: string,
): Promise<string> => {
  // this endpoint should return url to upload image
  const response = await prepareUploadLargeMOTGImage(name, mimeType, s3Folder);
  if (!response.success || !response.uploadURL || !response.imageShortUrl) {
    return "";
  }

  // upload image
  const result = await axios.request({
    url: response.uploadURL,
    method: "PUT",
    data: image,
    headers: { "Content-Type": mimeType },
    onUploadProgress: (p) => {
      // empty for now. can be used to indicate progress
    },
  });

  return response.imageShortUrl;
};

export interface ImageLoadResult {
  image: HTMLImageElement | null;
  dataURL: string | null;
}

export const loadImage = (src: string, getDataURL = true): Promise<ImageLoadResult> => {
  return new Promise((resolve, reject) => {
    if (!src) {
      resolve({ image: null, dataURL: null });
    }

    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = src;

    image.onload = () => {
      if (!getDataURL) {
        resolve({ image, dataURL: null });

        return;
      }

      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      const context = canvas.getContext("2d");

      if (!context) {
        console.log("No memory for canvas");
      }

      if (context && image.width && image.height) {
        context.drawImage(image, 0, 0, image.width, image.height);
      }
      const dataURL = canvas.toDataURL("image/jpg");

      releaseCanvas(canvas);

      resolve({ image, dataURL });
    };

    image.onerror = reject;
  });
};

/**
   * Render moment header over image
   * @param context - canvas context to render on
   * @param canvasWidth - area width
   * @param headerData - header information
   * @param scaleToImageSize - scale header to image proportions (share / download)
   */
export const renderMomentHeaderOldVersion = (
  context: CanvasRenderingContext2D,
  canvasWidth: number,
  headerData: MomentHeaderData,
  scaleToImageSize?: boolean,
): void => {
  context.imageSmoothingEnabled = true;
  context.imageSmoothingQuality = "high";

  // we can scale entire image
  // scale downloaded / shared images to the image proportions
  const IMAGE_WIDTH_PART = 0.8;
  const scale = !scaleToImageSize ? 1 : (canvasWidth * IMAGE_WIDTH_PART) / 300;

  // limit width for exporting image
  const { maxWidth } = headerData;
  const headerWidth = maxWidth && canvasWidth > maxWidth ? maxWidth : canvasWidth;
  const topBoxLeftX = (canvasWidth - headerWidth) / 2;
  const middleX = headerWidth / 2 + topBoxLeftX;

  // render top box for the moment text
  const topBoxHeight = 30 * scale;
  context.fillStyle = headerData.team1Color || COLORS_PARTIAL.GREEN_DARK;
  context.strokeStyle = headerData.team1Color || COLORS_PARTIAL.GREEN_DARK;
  context.beginPath();
  context.rect(0, 0, canvasWidth, topBoxHeight);
  context.fill();
  context.closePath();

  // render moment text in the top box
  if (headerData.moment.text) {
    context.beginPath();

    context.fillStyle = COLORS_PARTIAL.WHITE;
    context.font = `700 ${20 * scale}px ${FontFamilyTypeEnum_PARTIAL.Copperplate}`;
    let measurementsFirstLine = context.measureText(headerData.moment.text);
    let textWidth = measurementsFirstLine.width;
    // reduce font to place long description
    if (textWidth > canvasWidth) {
      const fontScale = canvasWidth / textWidth;
      let newFontSize = 20 * scale * fontScale * 0.95;
      if (newFontSize < 8) {
        newFontSize = 8;
      }

      context.font = `700 ${newFontSize}px ${FontFamilyTypeEnum_PARTIAL.Copperplate}`;
      measurementsFirstLine = context.measureText(headerData.moment.text);
      textWidth = measurementsFirstLine.width;
    }

    context.fillText(headerData.moment.text, canvasWidth / 2 - textWidth / 2, 22 * scale);

    context.closePath();
  }

  // render only header for "Other" sport or if we should skip scoreboard
  const sport = headerData.selectedGameData?.sportName;
  const isOtherSport = sport && sport.startsWith("Other");
  if (isOtherSport || headerData.hideScoreboard) {
    return;
  }

  const width = headerWidth * scale < 300 ? headerWidth * scale : 300;
  const coef = (width * scale) / 300;

  // render header box
  context.fillStyle = COLORS_PARTIAL.BLACK;
  context.strokeStyle = COLORS_PARTIAL.BLACK;
  context.beginPath();
  context.rect(middleX - 150 * coef, topBoxHeight, 300 * coef, 45 * coef);
  context.fill();
  context.closePath();

  // render bottom line
  context.beginPath();

  context.fillStyle = COLORS_PARTIAL.WHITE;
  const dateText = headerData.selectedGameData
    ? `${format(headerData.selectedGameData.startDate, "MMM")} ${format(headerData.selectedGameData.startDate, "dd")}, ${format(headerData.selectedGameData.startDate, "yyyy")}`
    : "";
  context.font = `700 ${9 * coef}px ${FontFamilyTypeEnum_PARTIAL.POPPINS}`;
  context.fillText(dateText, middleX - 150 * coef + 3 * scale, topBoxHeight + 40 * coef);

  const stadiumText = headerData.selectedGameData ? headerData.selectedGameData.stadium : "";
  context.font = `700 ${10 * coef}px ${FontFamilyTypeEnum_PARTIAL.POPPINS}`;
  const measurementsStadiumText = context.measureText(stadiumText);
  const stadiumTextWidth = measurementsStadiumText.width;
  context.fillText(stadiumText, middleX - stadiumTextWidth / 2, topBoxHeight + 40 * coef);

  context.closePath();

  const logoCoef = headerData.logoImage.width / headerData.logoImage.height;
  const logoWidth = 50 * coef;
  context.drawImage(headerData.logoImage, middleX + 95 * coef,
    topBoxHeight + 30 * coef, logoWidth, logoWidth / logoCoef);

  // render period information and time
  context.beginPath();
  context.fillStyle = COLORS_PARTIAL.WHITE;
  context.font = `700 ${12 * coef}px ${FontFamilyTypeEnum_PARTIAL.POPPINS}`;
  if (headerData.moment.momentPeriodText || headerData.moment.momentPeriodType) {
    const periodText = `${headerData.moment.momentPeriodText || ""} ${headerData.moment.momentPeriodType || ""}`;
    const measurementsPeriodText = context.measureText(periodText);
    const periodTextWidth = measurementsPeriodText.width;
    context.fillText(periodText, middleX - periodTextWidth / 2, topBoxHeight + 13 * coef);
  }

  const momentTime = headerData.moment.momentTime || "";
  if (momentTime) {
    const measurementsMomentTime = context.measureText(momentTime);
    const momentTimeWidth = measurementsMomentTime.width;
    context.fillText(momentTime, middleX - momentTimeWidth / 2, topBoxHeight + 28 * coef);
  }

  context.closePath();

  // render team rounded boxes 105 x 30
  renderRoundedBox(context, middleX - 150 * coef + 3 * scale,
    topBoxHeight + 3 * scale,
    105 * coef, 24 * coef, 5 * scale, headerData.team1Color || COLORS_PARTIAL.GREEN_DARK);
  renderRoundedBox(context, middleX + 150 * coef - 108 * coef,
    topBoxHeight + 3 * scale,
    105 * coef, 24 * coef, 5 * scale, headerData.team2Color || COLORS_PARTIAL.GREY_DARK);

  // render scores
  context.beginPath();
  context.fillStyle = COLORS_PARTIAL.WHITE;
  if (headerData.moment.team2Score || headerData.moment.team2Score === 0) {
    const team2Score = `${headerData.moment.team2Score}`;
    context.font = `500 ${25 * coef}px ${FontFamilyTypeEnum_PARTIAL.POPPINS}`;
    const measurementsScore2 = context.measureText(team2Score);
    const textWidthScore2 = measurementsScore2.width;
    context.fillText(team2Score, middleX - 50 * coef - textWidthScore2,
      topBoxHeight + 23 * coef);
  }

  if (headerData.moment.team1Score || headerData.moment.team1Score === 0) {
    const team1Score = `${headerData.moment.team1Score}`;
    context.font = `500 ${25 * coef}px ${FontFamilyTypeEnum_PARTIAL.POPPINS}`;
    const measurementsScore1 = context.measureText(team1Score);
    const textWidthScore1 = measurementsScore1.width;
    context.fillText(team1Score, middleX + 50 * coef, topBoxHeight + 23 * coef);
  }
  context.closePath();

  // render team logo images
  if (headerData.team2LogoImage && headerData.team2LogoImage.width) {
    const x = middleX - 137 * coef;
    context.drawImage(headerData.team2LogoImage, x, topBoxHeight + 3 * coef, 25 * coef, 25 * coef);
  }
  if (headerData.team1LogoImage && headerData.team1LogoImage.width) {
    const x = middleX + 107 * coef;
    context.drawImage(headerData.team1LogoImage, x, topBoxHeight + 3 * coef, 25 * coef, 25 * coef);
  }
};
